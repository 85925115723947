export const initOrttoTracking = () => {
    window.ap3c = window.ap3c || {};
    const ap3c = window.ap3c;
    ap3c.cmd = ap3c.cmd || [];
    ap3c.cmd.push(() => {
      ap3c.init(
        process.env.REACT_APP_ORTTO_TRACKING_ID,
        'https://capture-api-us.ortto.app/'
      );
      ap3c.track({ environment: 'QA', v: 0 }); // Tag events with "QA" environment
    });
    ap3c.activity = (act: any) => { ap3c.act = (ap3c.act || []); ap3c.act.push(act); };
  
    // Load external script for tracking
    const script = document.createElement('script');
    script.src = "https://cdn2l.ink/app.js";
    script.async = true;
    document.body.appendChild(script);
  };
// Track Home Page View
export const orttoTrackHomePageView = (pagePath: string) => {
    console.log('window.ap3c =>',window.ap3c);
    if (window.ap3c) {
    window.ap3c.track({
        event: 'web_experience_list_view',
        page_path: pagePath,
    });
}
};

// Track Experience Detail View
export const orttoTrackExperienceDetailView = (pagePath: string) => {
  window.ap3c.track({
    event: 'web_experience_detail_view',
    page_path: pagePath,
  });
};

// Track Book Experience Click
export const trackBookExperienceClick = (experienceId: string, experienceName: string) => {
  window.ap3c.track({
    event: 'web_book_experience_click',
    experience_id: experienceId,
    experience_name: experienceName,
  });
};

// Track Completed Transaction
export const trackCompletedTransaction = (experienceId: string, experienceName: string, value: string) => {
  window.ap3c.track({
    event: 'web_transaction_completed',
    experience_id: experienceId,
    experience_name: experienceName,
    value: parseFloat(value),
  });
};

